"use client";

import { ClerkProvider } from "@clerk/nextjs";
import { NextUIProvider } from "@nextui-org/react";
import { useRouter } from "next/navigation";
import { DebProvider } from "../willis/services/useMiniDeb";
import { TitleBarProvider } from "../willis/services/useTitleBar";


// Only if using TypeScript
declare module "@react-types/shared" {
  interface RouterConfig {
    routerOptions: NonNullable<
      Parameters<ReturnType<typeof useRouter>["push"]>[1]
    >;
  }
}

export function AppProviders({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  return (
    <ClerkProvider>
      <NextUIProvider navigate={router.push}>
        <DebProvider>
          <TitleBarProvider>
            {/* // It is ok to pass server components as children and they will remain server components */}
            {children}
          </TitleBarProvider>
        </DebProvider>
      </NextUIProvider>
    </ClerkProvider>
  );
}

export default AppProviders;
