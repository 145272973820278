import React, { createContext, useContext, useState, ReactNode } from "react";

// Define a type for the facial expressions
type FaceExpression = "default" | "smile" | "frown" | "shocked";

interface DebState {
  isVisible: boolean;
  faceExpression: FaceExpression;
  badgeNumber: number | null; // Badge number displayed on Deb
}

interface DebContextProps extends DebState {
  showDeb: () => void;
  hideDeb: () => void;
  changeFaceExpression: (expression: FaceExpression) => void;
  setBadgeNumber: (number: number | null) => void;
}

const DebContext = createContext<DebContextProps | undefined>(undefined);

export const DebProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [faceExpression, setFaceExpression] =
    useState<FaceExpression>("default");
  const [badgeNumber, setBadgeNumberState] = useState<number | null>(null);

  const showDeb = () => setIsVisible(true);
  const hideDeb = () => setIsVisible(false);
  const changeFaceExpression = setFaceExpression;
  const setBadgeNumber = setBadgeNumberState;

  return (
    <DebContext.Provider
      value={{
        isVisible,
        faceExpression,
        badgeNumber,
        showDeb,
        hideDeb,
        changeFaceExpression,
        setBadgeNumber,
      }}
    >
      {children}
    </DebContext.Provider>
  );
};

export const useMiniDeb = () => {
  const context = useContext(DebContext);
  if (!context) {
    throw new Error("useDeb must be used within a DebProvider");
  }
  return context;
};
